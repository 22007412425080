.profile-image {
  border-radius: 50%;
  margin: 2rem;
  height: auto;
  display: block;
  width: 10rem;
  height: 10rem;
}

.profile-flex-container {
  padding: 1rem;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: EB Garamond, serif;
  color: #d1cdc5;
  background-color: #454a4d;
  flex-direction: row;
}

.profile-info-flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-self: center;
}

.profile-text {
  margin: 0.25rem;
}
