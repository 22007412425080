.article-thumbnail-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 35rem;
  margin: 2rem 5rem 2rem 5rem ;
  max-height: 100%;
}

.thumbnail-img {
  cursor: pointer;
  min-width: 10rem;
  min-height: 10rem;
  max-width: 15rem;
}

.thumbnail-summary {
  margin-top: 1rem;
}

.thumbnail-date {
  margin-top: 1rem;
  text-align: center;
  width: 100%;
  font-size: larger;
  font-family: 'Brush Script MT', cursive;
}

.thumbnail-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
