html, body {
  margin: 0;
  height: 100%;
}

.app {
  margin: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}
.header {
  font-weight: 700;
  display: inline-block;
  color: inherit;
  text-decoration: inherit;
  background-color: transparent;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
  text-align: center;
  font-size: 2rem;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-app-container {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
}

body {
  font-family: EB Garamond, serif;
  --bg-opacity: 1;
  --text-opacity: 1;
  color: #d1cdc5;
  text-align: center;
  background-color: #202324;
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
}

.link{
  cursor: pointer;
}

.link:hover{
  color: #111;
  text-decoration: underline;
}

